<template>
  <v-row class="">
    <v-col cols="12" class="mx-auto" md="7" v-if="authUser">
      <v-card color="transparent" class="rounded-xl mb-10 pb-5" elevation="0">
        <v-card-title class="headline text-center justify-center">
          {{ $lang("referfriendsearncoins") }}
        </v-card-title>
        <v-img
          style="width: 100%"
          src="/artwork/referral.png"
          alt="Referral Program"
        />
        <v-card-text class="text-center">
          {{
            isHindi
              ? `यदि आप विकर्ण ऐप का उपयोग करना पसंद करते हैं तो, सिक्के कमाने के लिए अपने रेफरल कोड को दोस्तों और परिवार के साथ साझा करें। उन्हें सिक्कों का कूपन मिलेगा, आपको सिक्कों का कूपन मिलेगा।`
              : `If you enjoy using Vikarn app, share your referral code with friends and family to earn coins. they will get coupon of coins, you will get coupon of coins.`
          }}
        </v-card-text>
        <v-card-text style="cursor: pointer" @click="doCopy">
          <div class="overline text-center">
            {{ $lang("YOURREFERRALCODE") }}
          </div>
          <div
            style="border: 2px dashed green; text-align: center; color: green"
            class="display-2 py-3 rounded-xl"
          >
            {{ authUser.uid }}
          </div>
          <div class="caption text-right mr-5">*click to copy</div>
        </v-card-text>
      </v-card>
      <HaveReferralCode
        v-if="authUser"
        @sumitted="animator.animate(`confetti`)"
      />
      <v-card class="my-10" elevation="0" color="transparent">
        <v-card-title class="headline justify-center"
          >{{ isHindi ? `यह कैसे काम करता है?` : `How it works` }}
        </v-card-title>
        <v-card-text class="text-center">
          {{
            isHindi
              ? `जब आप अपने विशिष्ट रेफ़रल कोड का उपयोग करके किसी को विकर्ण पर रेफर करते हैं, तो उन्हें ₹20 के सिक्कों के लिए एक कूपन प्राप्त होगा, आपको ₹20 के सिक्कों के लिए भी एक कूपन प्राप्त होगा।`
              : `When you refer someone to Vikarn using your unique referral code,
          they'll receive a coupon for coins worth ₹20, you'll also receive a
          coupon for coins worth ₹20 as well.`
          }}
        </v-card-text>
        <v-row dense class="mt-3 justify-center text-center">
          <v-col cols="12" md="4">
            <v-card
              height="200"
              outlined
              class="
                px-2
                rounded-xl
                d-flex
                flex-column
                justify-center
                align-center
                text-center
              "
            >
              <v-avatar size="50" contain>
                <v-icon x-large>mdi-account</v-icon>
              </v-avatar>
              <div class="title">Tell your friends</div>
              <div class="subtitle">Tell your friends about Vikarn app.</div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              height="200"
              outlined
              class="
                px-2
                rounded-xl
                d-flex
                flex-column
                justify-center
                align-center
                text-center
              "
            >
              <v-avatar size="50" contain>
                <v-icon x-large>mdi-account</v-icon>
              </v-avatar>
              <div class="title">Friend gets ₹20 Coupon</div>
              <div class="subtitle">
                Your friend signs up and gets coupon for coins worth ₹20.
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              height="200"
              outlined
              class="
                px-2
                rounded-xl
                d-flex
                flex-column
                justify-center
                align-center
                text-center
              "
            >
              <v-avatar size="50" contain>
                <v-icon x-large>mdi-account</v-icon>
              </v-avatar>
              <div class="title">You earn ₹20 Coupon</div>
              <div class="subtitle">
                You earn ₹20 coupon after they Sign up.
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-btn
          block
          x-large
          @click="share"
          class="my-8 rounded-xl green accent-4 white--text"
        >
          Invite your friends
        </v-btn>
      </v-card>

      <AnimOverlay @attached="handleAnimAttached" />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import AnimOverlay from "./AnimOverlay.vue";
import { Share } from "@capacitor/share";
import HaveReferralCode from "../modules/HaveReferralCode";
export default {
  name: "referral-program",
  components: {
    AnimOverlay,
    HaveReferralCode,
  },

  data() {
    return {
      loading: false,
      couponCode: "",
      redeemedCoupons: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {},
  methods: {
    doCopy() {
      this.$clipboard(this.authUser.uid.toLowerCase());
      this.$store.dispatch("setAlert", {
        message: "Code copied to clipboard.",
        type: "dark primary white--text",
        icon: "mdi-check-circle",
      });
    },
    async share() {
      this.doCopy();
      await Share.share({
        title: "Invite your friends.",
        text:
          "I have been using Vikarn app for sometime now. and I think you could use it for your exam preparation as well. Use my code ( " +
          this.authUser.uid +
          " ) when signing up and get coupon of coins worth ₹20.",
        url: "https://vikarn.com/ref/" + this.authUser.uid,
        dialogTitle: "SignUp Now!",
      });
    },
    handleAnimAttached(animator) {
      this.animator = animator;
    },
    checkCouponCode() {
      if (!this.couponCode) {
        return;
      }
      this.loading = true;
      return axios
        .get("/coupons/redeem/" + this.couponCode)
        .then((res) => {
          this.animator.animate("confetti");
          setTimeout(() => {
            this.couponCode = null;
            this.$store.dispatch("auth/fetchUser");
            this.fetchRedemedCoupons(1);
          }, 1000);
        })
        .catch(() => {
          // this.animator.success();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchRedemedCoupons(page = 1) {
      return axios
        .get("coupons/redeemed?orderby=coupons.id|desc&perPage=30&page=" + page)
        .then((res) => {
          this.redeemedCoupons = res.data.data;
          // this.redeemedCoupons = data;
        })
        .finally(() => {});
    },
  },
  created() {
    this.fetchRedemedCoupons(1);
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.animator) {
    //     this.animator.animate("coupon");
    //   }
    // }, 500);
  },
};
</script>
